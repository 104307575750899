import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion';
import { Button } from 'antd';
import swissmade from '../images/swiss-made.png';
import geneve from '../images/geneve.svg';
import drops from '../images/drops2.svg';

const Header = ({ siteTitle }) => (
<header className="header">

		<div style={{ margin: `0 auto`, maxWidth: 960 }}>
			<h1>{siteTitle}</h1>
		</div>

		<motion.img
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			src={geneve}
			className="geneve-svg"
		/>

		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="header-svg">
			<img src={drops} className="drops-svg"/>
		</motion.div>

		<motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }} className="h2-title">
			Amplificateur de visibilité
			<br />
			<span style={{ fontWeight: 300 }}>pour entreprises genevoises</span>
		</motion.h2>

		<motion.h4 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }} className="h4-title">
            {/*Obtenez une offre personnalisée pour booster votre présence sur le web !*/}
			Obtenez des leads qualifiés grâce à nos Landing Pages !
		</motion.h4>

		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }} style={{ textAlign: 'center' }}>
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<a className="CTA" type="primary" size="large" href="#intro">
					Découvrir plus
				</a>
			</div>
		</motion.div>

		<motion.h4 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.8 }} style={{ color: '#fff', fontSize: '0.8rem', marginTop: 10}}>
			<span style={{padding: '8px', backgroundColor: 'rgba(66, 141, 137, 0.40)', borderRadius: '6px'}}><span className="site-title">{siteTitle}</span> Un service <img src={swissmade}/> proposé par <a href="https://nammu.ch" style={{color: '#fff'}}>nammu.ch</a></span>
		</motion.h4>
	</header>
);

export default Header
